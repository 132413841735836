/* 

/* ----- UNIVERSAL STYLE ------ */

:root {
  --background-color: linear-gradient(
    0deg,
    #01090e 0%,
    #052944 50%,
    rgba(0, 0, 0, 3) 80%
  );
  --box-color: rgb(5, 38, 62, 0.95);
  --title-color: #72b5d6;
  --main-text-color: #a0bdeb;
  --highlight-text-color: rgb(62, 130, 240);
  --glowing-color: #ffebcd;
  --link-color: rgb(122, 228, 203);
  --title-font: "Kanit", sans-serif;
  --text-font: "Titillium Web", sans-serif;
  --light-font: "Alexandria", sans-serif;
  font-size: calc(15px + 0.390625vw);
  background: var(--background-color);
  color: var(--main-text-color);
  scroll-behavior: smooth;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#video1 {
  position: absolute;
  z-index: -1;
  width: 100%;
  filter: brightness(1) blur(8px);
}

body {
  width: 100%;
}

body h1 {
  font-size: 2.8rem;
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
}
body h2 {
  font-size: 2rem;
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 400;
}
body h3 {
  font-size: 1.8rem;
  font-family: var(--title-font);
  color: var(--title-color);
}
body h4 {
  font-size: 1.4rem;
  font-family: var(--title-font);
}
body h5 {
  font-size: 0.9rem;
  font-family: var(--light-font);
  font-weight: 200;
}
body p {
  font-size: 0.9rem;
  font-family: var(--text-font);
}
.link {
  color: var(--link-color);
  font-size: 0.9rem;
  font-family: var(--light-font);
  font-weight: 200;
  text-decoration: none;
  display: inline-block;
  padding: 0.3rem 0;
}
.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
a:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.5s;
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  background: var(--box-color);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--main-text-color);
  border-radius: 3px;
  height: 1rem;
}

/* ----- HOME SECTION ------ */

#home {
  width: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#home h1 {
  margin: 1rem 0;
  animation: titleappear 700ms 800ms backwards;
}
#home h2 {
  margin: 1rem 0;
  text-align: center;
  animation: titleappear 700ms 900ms backwards;
}
#home button {
  justify-self: flex-end;
  margin: 1rem 0;
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid;
  color: var(--highlight-text-color);
  animation: titleappear 700ms 1000ms backwards;
}
#home button:hover {
  color: var(--link-color);
  cursor: pointer;
}
@keyframes titleappear {
  0% {
    transform: translate(-100px);
    opacity: 0;
  }
}
#home button a {
  color: var(--link-color);
  text-decoration: none;
}
#home button a p {
  margin: 0.3rem;
}
#home span {
  color: var(--highlight-text-color);
}

/* ----- HEADER ------ */

#navbar {
  position: sticky;
  z-index: 100;
  height: 3rem;
  top: 0;
  transition: all 500ms;
  padding: 0.4rem 0;
  background-color: var(--box-color);
  transition: transform 500ms;
  transition: top 500ms;
  display: flex;
  justify-content: space-between;
}
#navbar img {
  height: 2rem;
}
#navbar .logo {
  padding: 0 2rem;
}
#navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
#navbar li {
  font-family: var(--text-font);
  padding: 0 1.5rem;
}
#navbar li:hover {
  transform: scale(110%);
  transition: all 500ms;
}
#navbar a {
  text-decoration: none;
  transition: color 500ms;
  color: var(--main-text-color);
}
#navbar li:hover > p {
  color: var(--glowing-color);
  text-shadow: 0 0 10px var(--glowing-color);
}

@media only screen and (max-width: 800px) {
  #navbar li {
    padding: 0 0.5rem;
  }
  #navbar .logo {
    padding: 0 1rem;
  }
}

/* ----- ABOUT SECTION ------ */

#about {
  width: 80%;
  margin: 8% auto;
  text-align: justify;
}
#about .content {
  display: flex;
  align-items: center;
}
#about .text-container {
  width: 65%;
}
#about p {
  margin: 2rem 0;
  line-height: 1.4rem;
}
#about img {
  margin-left: 10%;
  width: 25%;
  border-radius: 10px;
  border: 3px solid var(--main-text-color);
}

@media only screen and (max-width: 700px) {
  #about .content {
    flex-direction: column;
  }
  #about .text-container {
    width: 90%;
  }
  #about img {
    width: 50%;
  }
}

/* ----- SKILLS SECTION ------ */

#skills {
  width: 80%;
  margin: auto;
}
#skills .grid {
  margin: 3rem auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  gap: 1rem 1.5rem;
}
@media only screen and (max-width: 800px) {
  #skills .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  #skills .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

#skills .grid_el {
  max-width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#skills .grid img {
  width: 90%;
  transition: transform 500ms;
  animation: movein 1s 1s backwards;
}
@keyframes movein {
  0% {
    transform: translate(-300px);
    opacity: 0;
  }
}
#skills .grid img:hover {
  transform: scale(1.2);
}
#skills .grid_el h5 {
  text-align: center;
  padding-top: 1.5rem;
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}
#skills .grid_el:hover > h5 {
  transform: scale(1.25);
  opacity: 1;
}

/* ----- PROJECTS SECTION ------ */

#projects {
  width: 90%;
  margin: auto;
  padding: 2rem 1rem;
}
.projects-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}
.project-box {
  width: 100%;
  margin: 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-video {
  width: 60%;
  border-radius: 20px;
}

.project-info {
  background-color: var(--box-color);
  box-shadow: 3px 3px 5px rgb(1, 3, 41);
  width: 40%;
  margin: 1rem;
  padding: 1rem;
}
.project-info p {
  margin: 1rem 0;
}
.project-info a {
  color: var(--link-color);
  text-decoration: none;
  display: block;
}

.skillIcon {
  width: 30px;
  margin: 8px;
}

@media only screen and (max-width: 800px) {
  .projects-container {
    margin: 1rem 0;
  }
  .project-box {
    flex-direction: column-reverse;
    margin: auto;
  }
  .reverse {
    flex-direction: column;
  }
  .project-video,
  .project-info {
    width: 100%;
    border-radius: 20px;
    margin: 0.3rem;
  }
}

/* ----- CONTACT SECTION ------ */

#contact {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
}
#contact .contact-options {
  width: 80%;
  margin: 5rem auto;
  display: grid;
  place-items: center;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
}
#contact img {
  max-width: 4rem;
  vertical-align: middle;
}

@media only screen and (max-width: 800px) {
  #contact .contact-options {
    width: 100%;
  }
  #contact img {
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  #contact {
    width: 100%;
    padding: 2rem 3rem;
  }
}

/* ----- FOOTERS SECTION ------ */

footer {
  overflow: hidden;
  border-radius: 50%;
  height: 1px;
  animation: glow 2s infinite alternate-reverse;
}

@media only screen and (max-width: 800px) {
}

@keyframes glow {
  0% {
    box-shadow: 0px -5px 60px 10px rgb(17, 3, 74);
  }
  100% {
    box-shadow: 0px -5px 60px 17px rgb(18, 40, 113);
  }
}
